<template>
	<w-section no-margin :title="value.provider.name + (value.label ? ` (${value.label})` : '') + ' :'">
		<template v-slot:header>
			<w-layout v-if="$vuetify.breakpoint.mdAndUp" justify-end row>
				<w-flex mr-2 shrink>
					<w-divider vertical />
				</w-flex>
				<w-flex align-center d-flex shrink>
					<w-tooltip bottom>
						<template v-slot:activator="{ on }">
							<w-switch class="ma-0" :value="copiedValue.is_active" :loading="activationLoading" v-on="on" @input="toggleConnectionActivation" />
						</template>
						<span>{{
							copiedValue.is_active ? $t('bill-scrapper.automations.deactivate_connection') : $t('bill-scrapper.automations.activate_connection')
						}}</span>
					</w-tooltip>
				</w-flex>
				<w-btn flat icon="edit" mini @click="updateConnection()">{{ $t('bill-scrapper.automations.update_connection') }}</w-btn>
				<w-btn :disabled="!!value.error" flat icon="sync" :loading="synchronizationLoading" mini @click="synchronizeConnection()">{{
					$t('bill-scrapper.automations.synchronize_connection')
				}}</w-btn>
				<w-btn-delete mini :text="$t('bill-scrapper.automations.delete_connection')" @click="deleteConnection()" />
			</w-layout>
			<w-menu v-else bottom :close-on-click="false">
				<template v-slot:activator="{ on }">
					<w-btn flat icon="more_vert" mini v-on="on" />
				</template>
				<w-list>
					<w-list-tile avatar @click.stop="toggleConnectionActivation(!copiedValue.is_active)">
						<w-list-tile-avatar>
							<w-switch class="ma-0" :input-value="copiedValue.is_active" :loading="activationLoading" />
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title
								v-text="copiedValue.is_active ? $t('bill-scrapper.automations.deactivate_connection') : $t('bill-scrapper.automations.activate_connection')"
							/>
						</w-list-tile-content>
					</w-list-tile>
					<w-list-tile avatar @click="updateConnection()">
						<w-list-tile-avatar>
							<w-icon color="primary">edit</w-icon>
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title v-t="'bill-scrapper.automations.update_connection'" />
						</w-list-tile-content>
					</w-list-tile>
					<w-list-tile avatar :disabled="!!value.error || synchronizationLoading" @click="synchronizeConnection()">
						<w-list-tile-avatar>
							<v-progress-circular v-if="synchronizationLoading" color="primary" indeterminate />
							<w-icon v-else color="primary">sync</w-icon>
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title v-t="'bill-scrapper.automations.synchronize_connection'" />
						</w-list-tile-content>
					</w-list-tile>
					<w-list-tile avatar @click="deleteConnection()">
						<w-list-tile-avatar>
							<w-icon color="error">delete</w-icon>
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title v-t="'bill-scrapper.automations.delete_connection'" />
						</w-list-tile-content>
					</w-list-tile>
				</w-list>
			</w-menu>
		</template>
		<w-error v-if="value.error" :text="value.error" />
		<w-layout pa-3>
			<v-flex v-if="value.last_update" xs12
				>{{ $t('bill-scrapper.automations.last_synchronization') }} : {{ $translateDateTime(value.last_update, 'long', false) }} -
				{{ $tc('bill-scrapper.automations.document_collected', value.number_of_documents) }}</v-flex
			>
			<v-flex v-else v-t="'bill-scrapper.automations.no_connection_was_establish'" xs12 />
		</w-layout>
	</w-section>
</template>

<script>
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'ConnectionItem',
	mixins: [BillScrapperModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			activationLoading: false,
			copiedValue: {},
			synchronizationLoading: false
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				if (val) {
					this.copiedValue = { ...val }
				}
			},
			immediate: true
		}
	},
	methods: {
		deleteConnection: function () {
			const connectionId = this.value.id
			this.$dialog
				.warning({
					text: this.$t('bill-scrapper.automations.delete_connection_question', { name: this.value.provider.name }),
					title: this.$t('bill-scrapper.automations.delete_connection'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service.deleteConnection(connectionId)
					}
				})
		},
		/**
		 * @return {Promise<void>}
		 */
		synchronizeConnection: function () {
			const connectionId = this.value.id
			this.synchronizationLoading = true

			return new Promise(resolve => {
				const result = this.service.synchronizeConnection(connectionId)

				resolve(result)
			}).finally(() => {
				this.synchronizationLoading = false
			})
		},
		toggleConnectionActivation: function (toggleValue) {
			this.activationLoading = true
			let promise = null
			if (toggleValue) {
				promise = this.service.activateConnection(this.value.id)
			} else {
				promise = this.service.deactivateConnection(this.value.id)
			}
			return promise.finally(() => {
				this.activationLoading = false
			})
		},
		updateConnection: function () {
			const connection = this.copiedValue
			this.eventBus.emit(this.events.UPDATE_CONNECTION, connection)
		}
	}
}
</script>
